<script lang="ts" setup generic="T">

const props = defineProps<{
  query: T,
}>()

// @ts-ignore
const query = await props.query.useQuery()

type Query = typeof query

defineSlots<{
  loading: (props: Query) => any
  error: (props: Query) => any
  success: (props: Query) => any
}>()

</script>
<template>
  <UButton icon="i-heroicons-refresh" @click="query.refresh()">
    Refresh
  </UButton>
  <slot v-if="query.pending.value" name="loading" v-bind="query"></slot>
  <slot v-else-if="query.error.value" name="error" v-bind="query"></slot>
  <slot v-else-if="query.data.value" name="success" v-bind="query"></slot>
</template>