<script setup lang="ts">
const { $client } = useNuxtApp();
const stats = await $client.stats.home.useQuery();
</script>
<template>
  <UCard>
    <h1>home</h1>
    <div class="space-x-2">
      <UButton to="/admin/dashboard" label="Dashboard" />
      <UButton to="/todos" label="Todos" />
      <UButton to="/posts" label="Posts" />
      <UButton to="/organizations" label="Organizations" />
    </div>

    <code>
      <Async :query="$client.stats.home">
        <template #success="{ data }">
          <pre>
this content is cached for 5 minutes
todos quantity: {{ data.value?.totalTodos }}
users quantity: {{ data.value?.totalUsers }}
organizations quantity: {{ data.value?.totalOrganizations }}
server side rendered times: {{ data.value?.serverSideGeneratedCount }}
generated at: {{ data.value?.generatedAt }}
          </pre>
        </template>
        <template #error="{ error }">
          {{ error.value.message }}
        </template>
        <template #loading="{ data }"> loading... </template>
      </Async>
    </code>
  </UCard>
</template>
